import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SelectItem, PrimeNGConfig } from "primeng/api";
import { finalize } from 'rxjs/operators';


export interface Tutorial {
  id?: any;
  code_product?: string,
  name_product?: string,
  name_kategori?: string,
  name_brand?: string,
  stock?: string,
  harga?: string,
  // name?: string,
  // phonename_product?: string,
  // phone_number?: string,
  keterangan?: string,
  // template_option?: string,
  fileimage?: string,
  event?: string,
  state?: any;
  // published?: boolean;
  action?: string
}


@Component({
  selector: 'app-mst-products',
  templateUrl: './mst-products.component.html',
  styleUrls: ['./mst-products.component.css']
})
export class MstProductsComponent implements OnInit {

  isLoading$ = false;

  image: File | undefined;
  resData: any;
  selectedFile: any;
  //  selectedFile = null;

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  code_product: any;
  name_product: any;
  stock: any;
  harga: any;
  keterangan: any;
  // name: any;
  // phone_number: any;
  // address: any;
  // template_option: any;
  // fileimage: any;
  id: any;

  // type_id: any;

  // purc_price: any;
  // sell_price: any;
  // weight: any;

  // countries: any[] = [];
  // selectedShipperPhoneNumberCountry: any = {};

  action: any = '';
  // phonename_product: any;

  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  typeItemsbrands: any[] = [];
  selectedTypeItembrands: any | undefined;

  typeItemsH: any[] = [];
  selectedTypeItemH: any | undefined;

  // cities: any[] = [];

  // selectedCities: any[] | undefined;

  ngOnInit(): void {
    this.retrieveTutorials();
    // this.fetchDropdownValues();

    this.retrieveCombodatatypeItems();
    this.retrieveCombodatatypeItemsH();
    this.retrieveCombodatatypeItemsbrands();



    this.primengConfig.ripple = true;
  }

  // event: any;
  angForm: FormGroup | any;
  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig
  ) {
    this.createForm();

  }


  createForm() {
    this.angForm = this.fb.group({
      code_product: ['', [Validators.required]],
      name_product: ['', [Validators.required]],
      stock: ['', [Validators.required]],
      harga: ['', [Validators.required]],
      keterangan: ['', [Validators.required]],
      // address: ['', [Validators.required]],
      // template_option: ['', [Validators.required]]
    });


  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.id = null;
    this.code_product = null;
    this.name_product = null;
    this.stock = '';
    this.harga = '';
    this.keterangan = '';
    this.selectedTypeItem = '';
    this.selectedTypeItemH = '';
    this.selectedTypeItembrands = '';
    this.files = [];
    // this.phone_number = null;
    // this.address = null;
    // this.template_option = null;
    // this.selectedShipperPhoneNumberCountry = {};
    this.modalService.open(content, { size: 'lg' });
  }





  // START
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('products/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  retrieveCombodatatypeItemsbrands(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('brand_produk/combodata', params)
      .subscribe(
        response => {
          const { data } = response;
          this.typeItemsbrands = data;
          console.log(this.typeItemsbrands, 'brands')
          // this.fetchSubItem(this.typeItems.id);
          // console.log(this.vcombo, 'this.vcombo');
          // this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  // fetchDropdownValues() {
  //   const params = this.getRequestParams(this.title, this.page, this.pageSize);
  //   // console.log(params, 'params')
  //   this.tutorialService.getcombo(`country/combodata`, params).subscribe((response: any) => {
  //     this.countries = response.data;
  //     // console.log(this.countries, 'ss')
  //   });
  // }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code_product: '',
    name_product: '',
    stock: '',
    harga: '',
    keterangan: '',
    // phone_number: '',
    // address: '',
    // template_option: '',
    fileimage: '',
    // published: false
  };

  submitted!: boolean;
  saveTutorial(): void {
    const data = {
      code_product: this.code_product,
      name_product: this.name_product,
      stock: this.stock,
      harga: this.harga,
      // phonename_product: this.selectedShipperPhoneNumberCountry,
      keterangan: this.keterangan,
      // address: this.address,
      // template_option: this.template_option,
    };

    if(this.files.length < 1) {
      this.toastService.show('Image must be 1 files or more!', { classname: 'bg-danger text-white', delay: 15000 });
      return;
    }

    this.isLoading$ = true;

    if (this.isCreated) {

      const formData: FormData = new FormData();
      // const formdata = new FormData();
      formData.append('id_brand', this.selectedTypeItembrands.id);
      formData.append('id_product_header', this.selectedTypeItemH.id);
      formData.append('id_kategori', this.selectedTypeItem.id);
      formData.append('code_product', this.code_product);
      formData.append('name_product', this.name_product);
      formData.append('stock', this.stock);
      formData.append('harga', this.harga);
      formData.append('keterangan', this.keterangan);
      // formData.append('fileimage', this.selectedFile);
      // console.log(this.files,'kk')
      // console.log(this.files.length,'kk')
      // if(this.files.length == 0){
      //   formData.append('fileimage', '0');
      // }
      for(var i=0; i<this.files.length; i++) {
        // console.log(this.files[i],'this.files[i]')
        formData.append('fileimage', this.files[i]);
      }
      // formData.append('fileimage', this.fileimage);
      // console.log(formData, 'data')
      // this.tutorialService.postDataI('shippers/create', formData)
      this.tutorialService.postData('products/create', formData)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      console.log(this.selectedTypeItemH == undefined, this.selectedTypeItemH == 'undefined', 'data');
      const formData: FormData = new FormData();
      formData.append('id_brand', this.selectedTypeItembrands == undefined ? '' : this.selectedTypeItembrands.id);
      formData.append('id_product_header', this.selectedTypeItemH == undefined ? '' : this.selectedTypeItemH.id);
      formData.append('id_kategori', this.selectedTypeItem.id);
      formData.append('code_product', data.code_product);
      formData.append('name_product', data.name_product);
      formData.append('stock', data.stock);
      formData.append('harga', data.harga);
      formData.append('keterangan', data.keterangan);
      // formData.append('fileimage', this.selectedFile);
      for(var i=0; i<this.files.length; i++) {
        // console.log(this.files[i],'this.files[i]')
        formData.append('fileimage', this.files[i]);
      }


      this.tutorialService.postData('products/update/' + this.id, formData)
      .pipe(
        finalize(() => this.isLoading$ = false)
      )
      .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal: any, tutorial: any, action: any) {
    console.log(tutorial,'tutorial')
    this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    this.id = tutorial.id;
    this.code_product = tutorial.code_product;
    this.name_product = tutorial.name_product;
    this.stock = tutorial.stock;
    this.harga = tutorial.harga;
    this.keterangan = tutorial.keterangan;

    this.files = [];
    // this.address = tutorial.address;
    // this.template_option = tutorial.template_option;
    // this.fileimage = tutorial.fileimage;
    console.log(tutorial,'tutorial')
    let selected = this.typeItems.find((e: any) => e.id == tutorial.id_kategori);
    let selected2 = this.typeItemsH.find((e: any) => e.id == tutorial.id_product_header);
    let selected3 = this.typeItemsbrands.find((e: any) => e.id == tutorial.id_brand);
    // // console.log(selectedShipper)
    this.selectedTypeItem = selected;
    this.selectedTypeItemH = selected2;
    this.selectedTypeItembrands = selected3;
    this.fadd_role.action = action;
  }

  deleteShippers(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`products/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  onAutoCompleteSelected(event: any, tipe: string) {
    const val = {
      value: event,
    };
    this.onDropdownChange(val, tipe);
  }

  onDropdownChange(event: any, tipe: string) {
    // console.log(event.value)

    // if(tipe === 'phone_number') {
    //   this.selectedShipperPhoneNumberCountry = event.value;
    //   // console.log('selectedShipperPhoneNumberCountry',this.selectedShipperPhoneNumberCountry);
    // }
    if(tipe === 'type_id') {
      // this.fetchSubItem(event.value.id);
      this.selectedTypeItem = event.value;
    } if(tipe === 'type_idbrands') {
      // this.fetchSubItem(event.value.id);
      this.selectedTypeItembrands = event.value;
    } if(tipe === 'type_idh') {
      // this.fetchSubItem(event.value.id);
      this.selectedTypeItemH = event.value;
    }
    // else if(tipe === 'subtype_id') {
    //   this.selectedSubTypeItem = event.value;
    // }
  }

  // onDropdownChange(event: any, tipe: string) {
  //   this.selectedShipperPhoneNumberCountry = event.value;
  // }

  // onDropdownClear(tipe: string) {
  //   // if(tipe === 'shipper_phone') {
  //   this.selectedShipperPhoneNumberCountry = {};
  //   // }
  // }

  onDropdownClear(tipe: string) {
    // if(tipe === 'phone_number') {
    //   this.selectedShipperPhoneNumberCountry = {};
    // }
     if(tipe === 'type_id') {
      this.selectedTypeItem = {};
     }if(tipe === 'type_idbrands') {
      this.selectedTypeItembrands = {};
     } if(tipe === 'type_idh') {
      this.selectedTypeItemH = {};
     }
    // } else if(tipe === 'subtype_id') {
    //   this.selectedSubTypeItem = {};
    // }
  }




  retrieveCombodatatypeItems(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('kategori_produk/combodata', params)
      .subscribe(
        response => {
          const { data } = response;
          this.typeItems = data;
          console.log(this.typeItems, 'tset')
          // this.fetchSubItem(this.typeItems.id);
          // console.log(this.vcombo, 'this.vcombo');
          // this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  retrieveCombodatatypeItemsH(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    // this.tutorialService.getAlls('products/combodata', params)
    this.tutorialService.getAlls('products/combodataheader', params)
      .subscribe(
        response => {
          const { data } = response;
          this.typeItemsH = data;
          // console.log(this.typeItems, 'tset')
          // this.fetchSubItem(this.typeItems.id);
          // console.log(this.vcombo, 'this.vcombo');
          // this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  file: any;
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  files: File[] = [];
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }



}
