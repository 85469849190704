<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <!-- row -->
  <div class="container-fluid">
    <div class="row">
      <h2>Selamat Datang di Sistem Unicomputer</h2>
    </div>
  </div>
</div>


<!--**********************************
    Content body end
***********************************-->
