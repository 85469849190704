<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Produk</a></li>
          </ol>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input type="text" class="form-control" placeholder="Search by title" [(ngModel)]="title" />
        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i
          class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i
          class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Simulasi Produk Pelanggan</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Nama</th>
                    <th scope="col">No.Telp/HP</th>
                    <th scope="col">Laptop</th>
                    <th scope="col">Total Harga</th>
                    <!-- <th scope="col">Kode</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Stok</th>
                    <th scope="col">Harga</th> -->
                    <!--<th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <td scope="row">
                      {{ x+1 }}
                    </td>
                    <td>
                      {{ tutorial.nama }}
                    </td>
                    <td>
                      {{ tutorial.no_telp }}
                    </td>
                    <td>
                      {{ tutorial.produkheader }}
                    </td>
                    <td>
                      {{ currencyStr(tutorial.total_harga) }}
                    </td>
                    <!-- <td>
                      {{ tutorial.code_product }}
                    </td>
                    <td>
                      {{ tutorial.name_product }}
                    </td>
                    <td>
                      {{ tutorial.stock }}
                    </td>
                    <td>
                      {{ tutorial.harga }}
                    </td> -->
                    <!-- <td>{{ tutorial.name }}</td>
                    <td>{{ tutorial.phone_number }}</td>
                    <td>{{ tutorial.address }}</td> -->
                    <td>
                      <button type="button" class="btn btn-rounded btn-success"
                        (click)="editUser(sendMessageModal, tutorial, 'view')"><span
                          class="btn-icon-left text-success"><i class="fa fa-eye color-success"></i>
                        </span>Detail</button>&nbsp;
                      <!-- <button type="button" class="btn btn-rounded btn-info"
                        (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                          class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                        </span>Edit</button>&nbsp; -->
                      <button type="button" class="btn btn-rounded btn-danger" (click)="deleteSimulasi(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                        </span>Remove</button>&nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)">
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize"
                  (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal Harga -->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal id="a">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="fadd_role.action != 'view'">Form Simulasi Harga Pelanggan</h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="fadd_role.action == 'view'">Detail Simulasi Harga Pelanggan</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- START FORM -->
    <div class="form-validation">
      <form class="form-valide" action="#" method="post" [formGroup]="angForm" novalidate>

        <div class="row mb-6" *ngIf="fadd_role.action == 'view'">
          <div class="col-lg-12">
            <div class="card">
              <!-- <div class="card-header">
                <div class="card-title">Data Customer</div>
              </div> -->
              <div class="card-body">
                <div class="table-responsive mb-4">
                  <span>Barang: {{produkheader}}</span>
                  <p>Keterangan: {{keterangan}}</p>
                <!-- START TABLE -->
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>No Telp/HP</th>
                        <th>Email</th>
                        <th>Alamat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{nama}}</td>
                        <td>{{no_telp}}</td>
                        <td>{{email}}</td>
                        <td>{{alamat}}</td>
                      </tr>
                    <tbody>
                  </table>
                  <!-- END TABLE -->
                  <!-- <hr> -->
                  <!-- START TABLE -->

                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Part</th>
                          <th>Nominal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="harga1 != 0">
                          <td>{{name_product1}}</td>
                          <td class="text-right">{{ currencyStr(harga1)}}</td>
                        </tr>
                        <tr *ngIf="harga2 != 0">
                          <td>{{name_product2}}</td>
                          <td class="text-right">{{ currencyStr(harga2)}}</td>
                        </tr>
                        <tr *ngIf="harga3 != 0">
                          <td>{{name_product3}}</td>
                          <td class="text-right">{{ currencyStr(harga3)}}</td>
                        </tr>
                        <tr *ngIf="harga4 != 0">
                          <td>{{name_product4}}</td>
                          <td class="text-right">{{ currencyStr(harga4)}}</td>
                        </tr>
                        <tr *ngIf="harga5 != 0">
                          <td>{{name_product5}}</td>
                          <td class="text-right">{{ currencyStr(harga5)}}</td>
                        </tr>
                        <tr *ngIf="harga6 != 0">
                          <td>{{name_product6}}</td>
                          <td class="text-right">{{ currencyStr(harga6)}}</td>
                        </tr>
                        <tr *ngIf="harga7 != 0">
                          <td>{{name_product7}}</td>
                          <td class="text-right">{{ currencyStr(harga7)}}</td>
                        </tr>
                        <tr *ngIf="harga8 != 0">
                          <td>{{name_product8}}</td>
                          <td class="text-right">{{ currencyStr(harga8)}}</td>
                        </tr>
                        <tr *ngIf="harga9 != 0">
                          <td>{{name_product9}}</td>
                          <td class="text-right">{{ currencyStr(harga9)}}</td>
                        </tr>
                        <tr *ngIf="harga10 != 0">
                          <td>{{name_product10}}</td>
                          <td class="text-right">{{ currencyStr(harga10)}}</td>
                        </tr>
                        <tr *ngIf="harga11 != 0">
                          <td>{{name_product11}}</td>
                          <td class="text-right">{{ currencyStr(harga11)}}</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td class="text-right">{{ currencyStr(total_harga)}}</td>
                        </tr>
                      <tbody>
                    </table>
                    <!-- END TABLE -->
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-6" *ngIf="fadd_role.action != 'view'">
          <div class="col-md-6">
            <div class="form-group">
              <label class="text-black font-w600">Pilih Produk</label>
              <p-dropdown
                [options]="typeItemsH"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemH"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Produk"
                (onChange)="onDropdownChange($event, 'type_idh')"
                (onClear)="onDropdownClear('type_idh')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemH">
                    <div>{{ selectedTypeItemH.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="row mb-6" *ngIf="fadd_role.action != 'view'">

          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Ganti LCD</label>
              <p-dropdown
                [options]="typeItemslcd"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemlcd"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Produk Header"
                (onChange)="onDropdownChange($event, 'type_idlcd')"
                (onClear)="onDropdownClear('type_idlcd')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemlcd">
                    <div>{{ selectedTypeItemlcd.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Ganti Keyboard</label>
              <p-dropdown
                [options]="typeItemsKeyboard"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemKeyboard"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Produk Header"
                (onChange)="onDropdownChange($event, 'type_idkeyboard')"
                (onClear)="onDropdownClear('type_idkeyboard')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemKeyboard">
                    <div>{{ selectedTypeItemKeyboard.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Battery</label>
              <p-dropdown
                [options]="typeItemsbattery"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItembattery"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Produk Header"
                (onChange)="onDropdownChange($event, 'type_idbattery')"
                (onClear)="onDropdownClear('type_idbattery')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItembattery">
                    <div>{{ selectedTypeItembattery.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Adaptor</label>
              <p-dropdown
                [options]="typeItemsadaptor"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemadaptor"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select Adaptor"
                (onChange)="onDropdownChange($event, 'type_idadaptor')"
                (onClear)="onDropdownClear('type_idadaptor')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemadaptor">
                    <div>{{ selectedTypeItemadaptor.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Windows Error</label>
              <p-dropdown
                [options]="typeItemswindows"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemwindows"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Windows Error"
                (onChange)="onDropdownChange($event, 'type_idwindows')"
                (onClear)="onDropdownClear('type_idwindows')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemwindows">
                    <div>{{ selectedTypeItemwindows.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
           <div class="form-group">
             <label class="text-black font-w600">Engsel</label>
             <p-dropdown
               [options]="typeItemsengsel"
               optionLabel="name_product"
               [filter]="true"
               [disabled]="action == 'view'"
               filterBy="name_product"
               [showClear]="true"
               styleClass="w-100"
               [(ngModel)]="selectedTypeItemsengsel"
               [ngModelOptions]="{standalone: true}"
               placeholder="Select a Engsel"
               (onChange)="onDropdownChange($event, 'type_idengsel')"
               (onClear)="onDropdownClear('type_idengsel')"
             >
               <ng-template pTemplate="selectedItem">
                 <div class="" *ngIf="selectedTypeItemsengsel">
                   <div>{{ selectedTypeItemsengsel.name_product }}</div>
                 </div>
               </ng-template>
               <ng-template let-item pTemplate="item">
                 <div class="">
                   <div>{{ item.name_product }}</div>
                 </div>
               </ng-template>
             </p-dropdown>
           </div>
         </div>
           <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Mati Total</label>
              <p-dropdown
                [options]="typeItemsmatitotal"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemsmatitotal"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Mati Total"
                (onChange)="onDropdownChange($event, 'type_idmatitotal')"
                (onClear)="onDropdownClear('type_idmatitotal')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemsmatitotal">
                    <div>{{ selectedTypeItemsmatitotal.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Cleaning</label>
              <p-dropdown
                [options]="typeItemscleaning"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemscleaning"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Cleaning"
                (onChange)="onDropdownChange($event, 'type_idcleaning')"
                (onClear)="onDropdownClear('type_idcleaning')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemscleaning">
                    <div>{{ selectedTypeItemscleaning.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">RAM</label>
              <p-dropdown
                [options]="typeItemsram"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemsram"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a RAM"
                (onChange)="onDropdownChange($event, 'type_idram')"
                (onClear)="onDropdownClear('type_idram')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemsram">
                    <div>{{ selectedTypeItemsram.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">SSD</label>
              <p-dropdown
                [options]="typeItemsssd"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemsssd"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a SSD"
                (onChange)="onDropdownChange($event, 'type_idssd')"
                (onClear)="onDropdownClear('type_idssd')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemsssd">
                    <div>{{ selectedTypeItemsssd.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Maintenance</label>
              <p-dropdown
                [options]="typeItemsmaintenance"
                optionLabel="name_product"
                [filter]="true"
                [disabled]="action == 'view'"
                filterBy="name_product"
                [showClear]="true"
                styleClass="w-100"
                [(ngModel)]="selectedTypeItemsmaintenance"
                [ngModelOptions]="{standalone: true}"
                placeholder="Select a Produk Header"
                (onChange)="onDropdownChange($event, 'type_idmaintenance')"
                (onClear)="onDropdownClear('type_idmaintenance')"
              >
                <ng-template pTemplate="selectedItem">
                  <div class="" *ngIf="selectedTypeItemsmaintenance">
                    <div>{{ selectedTypeItemsmaintenance.name_product }}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="">
                    <div>{{ item.name_product }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-black font-w600">Total</label>
              <div *ngIf="status1">{{ currencyStr(harga1+harga2+harga3+harga4+harga5+harga6+harga7+harga8+harga9+harga10+harga11)}}</div>
              <div *ngIf="!status1">Belum Ada yang di pilih!</div>
            </div>
          </div>
        </div>

        <hr>
        <div class="row mb-6" *ngIf="fadd_role.action != 'view'">
          <div class="col-md-12 mb-6">
              <div class="welcome-text">
                  <p class="mb-0 text-black">Form Customer</p>
              </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Nama</label>
              <input type="text" class="form-control" id="nama" formControlName="nama"
                placeholder="Nama.." [(ngModel)]="nama">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>No.Telp/HP</label>
              <input type="text" class="form-control" id="no_telp" formControlName="no_telp"
                placeholder="No.Telp/HP.." [(ngModel)]="no_telp">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" id="email" formControlName="email"
                placeholder="Email.." [(ngModel)]="email">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Alamat</label>
              <textarea class="form-control" id="alamat" rows="5" [(ngModel)]="alamat"
                formControlName="alamat" placeholder="alamat"></textarea>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Keterangan</label>
              <textarea class="form-control" id="keterangan" rows="5" [(ngModel)]="keterangan"
                formControlName="keterangan" placeholder="keterangan"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12">


            <div class="text-center" *ngIf="fadd_role.action != 'view'">
              <button type="submit" (click)="saveSimulasi()" class="btn btn-primary w-100 mb-5">
                <ng-container *ngIf="(isLoading$) === false">
                  <span class="indicator-label">{{isCreated ? 'Submit':'Update'}}</span>
                </ng-container>
                <ng-container *ngIf="isLoading$">
                  <span class="indicator-progress" [style.display]="'block'">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </ng-container>
              </button>

            </div>

          </div>
        </div>
      </form>
    </div>
    <!-- END FORM -->
  </div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
