<!--**********************************
    Footer start
***********************************-->
<div class="footer">
    <div class="copyright">
        <p>Copyright © UNICOMPUTER 2023</p>
    </div>
</div>
<!--**********************************
    Footer end
***********************************-->
