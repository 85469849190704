<!--**********************************
    Content body start
***********************************-->
<!-- <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet"> -->
<!-- <link href="https://cdn.quilljs.com/1.3.6/quill.bubble.css" rel="stylesheet"> -->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">News</a></li>
          </ol>
        </div>

      </div>


    </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input type="text" class="form-control" placeholder="Search by title" [(ngModel)]="title" />
        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
      <!-- <a href="javascript:void(0);" class="btn btn-info btn-rounded mb-3 mr-3" (click)="opensethargashipper(sendMessageModalhgr)"><i class="fa fa-book scale5 mr-2"></i>Set Harga</a> -->
      <!-- <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="openimport(sendModalImport)"><i class="fa fa-cloud-upload scale5 mr-2"></i>Import</a> -->
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List News</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Title</th>
                    <th scope="col">Type</th>
                    <th scope="col">Image</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col">Description</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                   
                    <th scope="row">
                      {{ x+1 }}
                    </th>
                    <td>
                      {{ tutorial.titles }}
                    </td>
                    <td>
                      {{ tutorial.is_type }}
                    </td>
                    
                    <td>
                      <img src="{{newsImg}}{{ tutorial.images }}" height="100">
                    </td>
                    <td>
                      {{ tutorial.is_publish }}
                    </td>
                    <!-- <td>{{ tutorial.keterangan }}</td> -->
                    <td>

                      <button type="button" class="btn btn-rounded btn-info"
                        (click)="editNews(sendMessageModal, tutorial, 'update')"><span
                          class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                        </span>Edit</button>&nbsp;
                        <button type="button" class="btn btn-rounded btn-danger" (click)="deleteSliders(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button>&nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)">
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize"
                  (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal Harga -->

<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form News</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- START FORM -->
    <div class="form-validation">
      <form class="form-valide" action="#" method="post" [formGroup]="angForm" novalidate>
        <div class="row">
          <div class="col-xl-12">

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="titles">Title
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="titles" formControlName="titles" placeholder="Enter a Title.." [(ngModel)]="titles"
                >
                <div
                  *ngIf="angForm.controls['titles'].invalid && (angForm.controls['titles'].dirty || angForm.controls['titles'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['titles'].errors.required">
                    Titles is required.
                  </div>
                </div>
              </div>
            </div>

          

            <div class="form-group row">
              <label class="col-lg-12 col-form-label" for="keterangan">Keterangan <span class="text-danger">*</span>
              </label>
              <div class="col-lg-12">
                <!-- <p-editor
                class="form-control"
                [(ngModel)]="keterangan"
                name="keterangan"
                [styles]="{'min-height': '400px'}">
                </p-editor> -->
                <!-- <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Pengumuman is required',
                  control: registrationForm.controls['editor']
                }"
                ></ng-container> -->

                <p-editor [(ngModel)]="keterangan"
                name="keterangan"
                formControlName="keterangan" [style]="{ height: '320px' }"></p-editor>
                <!-- <textarea class="form-control" id="keterangan" rows="5" [(ngModel)]="keterangan" formControlName="keterangan"
                  placeholder="Keterangan?"></textarea> -->
                <!-- <div
                  *ngIf="angForm.controls['keterangan'].invalid && (angForm.controls['keterangan'].dirty || angForm.controls['keterangan'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['keterangan'].errors.required">
                    Keterangan is required.
                  </div>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-12 col-form-label" for="is_type">Type <span class="text-danger">*</span>
              </label>
              <div class="col-lg-12">
                <select class="form-control default-select" id="is_type" [(ngModel)]="is_type" formControlName="is_type" name="is_type">
                  <option ng-value="">Please select</option>
                  <option ng-value="NEWS">NEWS</option>
                  <option ng-value="TOP ERROR">TOP ERROR</option>
                  <option ng-value="LCD">LCD</option>
                  <option ng-value="KEYBOARD">KEYBOARD</option>
                  <option ng-value="HARDWARE">HARDWARE</option>
                  <option ng-value="SOFTWARE">SOFTWARE</option>
                  <option ng-value="TESTIMONI">TESTIMONI</option>
                  <option ng-value="OTHER">OTHER</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-12 col-form-label" for="status">Status <span class="text-danger">*</span>
              </label>
              <div class="col-lg-12">
                <select class="form-control default-select" id="is_publish" [(ngModel)]="is_publish" formControlName="is_publish" name="is_publish">
                  <option ng-value="">Please select</option>
                  <option ng-value="DRAFT">DRAFT</option>
                  <option ng-value="PUBLISH">PUBLISH</option>
                </select>
              </div>
            </div>

            
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Upload Image
              </label>
              <div class="col-lg-8">
                  <input type="file" class="form-control" name="fileimage" id="fileimage" (change)="onFileSelected($event)">
              </div>
            </div>


            <div class="form-group row">
              <div class="col-lg-8 ml-auto">
                <!-- <button type="submit" class="btn btn-primary"
                  [disabled]="angForm.pristine || angForm.invalid">Submit</button> -->
                <!-- <button (click)="onSubmit()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                <button (click)="saveSliders()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- END FORM -->
  </div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
