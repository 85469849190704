<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <div class="row page-titles mb-2">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Transaksi</a></li>
                  </ol>
                  <!-- <h4>List Consignee</h4> -->
              </div>

          </div>

      </div>

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Nama</th>
                      <th scope="col">Email</th>
                      <th scope="col">Telp</th>
                      <th scope="col">Alamat</th>
                      <th scope="col">Jenis Barang</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Keluhan</th>
                      <th scope="col">Service</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>
                          {{ tutorial.name }}
                        </td>
                        <td>
                          {{ tutorial.email }}
                        </td>
                        <td>
                          {{ tutorial.telp }}
                        </td>
                        <td>
                          {{ tutorial.address }}
                        </td>
                        <td>
                          {{ tutorial.is_type }}
                        </td>
                        <td>
                          {{ tutorial.is_unit }}
                        </td>
                        <td>
                          {{ tutorial.descriptions }}
                        </td>
                        <td>
                          {{ tutorial.is_service == 'P' ? 'Pickup Service' : 'Onsite Service' }}
                        </td>
                        <td>
                          <button type="button" class="btn btn-rounded btn-info" (click)="editBank(sendMessageModal, tutorial, 'update')"><span
                            class="btn-icon-left text-info"><i class="fa fa-eye color-info"></i>
                            </span>Show</button>&nbsp;
                          <button type="button" class="btn btn-rounded btn-danger" (click)="deleteBanks(tutorial)"><span
                              class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                              </span>Remove</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize" (pageChange)="handlePageChange($event)">
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize" (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>

              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Detail</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Nama <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="name"
              required
              [(ngModel)]="fadd_role.name"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="name"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Telp<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="telp"
              required
              [(ngModel)]="fadd_role.telp"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="telp"
            />
          </div>
				</div>

				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Email<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="email"
              required
              [(ngModel)]="fadd_role.email"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="email"
            />
          </div>
				</div>

				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Alamat<span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.address"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="address" rows="5"></textarea>
          
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Jenis Barang<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="is_type"
              required
              [(ngModel)]="fadd_role.is_type"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="is_type"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Unit<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="is_unit"
              required
              [(ngModel)]="fadd_role.is_unit"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="is_unit"
            />
          </div>
				</div>
        

				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Keluhan<span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.descriptions"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="descriptions" rows="5"></textarea>
          
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Jenis Service<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="is_service"
              required
              [(ngModel)]="fadd_role.is_service"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="is_service"
            />
          </div>
				</div>

				<!-- <div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div> -->
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
