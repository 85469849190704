<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Kategori Produk</a></li>
                  </ol>
                  <!-- <h4>List Consignee</h4> -->
              </div>

          </div>

      </div>

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Kategori Produk</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Kode</th>
                      <!-- <th scope="col">Brand</th> -->
                      <th scope="col">Nama</th>
                      <th scope="col">Keterangan</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>
                          {{ tutorial.code_kategori }}
                        </td>
                        <!-- <td>
                          {{ tutorial.name_brands }}
                        </td> -->
                        <td>
                          {{ tutorial.name_kategori }}
                        </td>
                        <td>
                          {{ tutorial.description }}
                        </td>
                        <td>
                          <button type="button" class="btn btn-rounded btn-info" (click)="editBank(sendMessageModal, tutorial, 'update')"><span
                            class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                            </span>Edit</button>&nbsp;
                          <button type="button" class="btn btn-rounded btn-danger" (click)="deleteBanks(tutorial)"><span
                              class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                              </span>Remove</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize" (pageChange)="handlePageChange($event)">
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize" (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>

              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Kategori Produk</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<!-- <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Pilih Brand </label>
            <p-dropdown
              [options]="typeItems"
              optionLabel="name"
              [filter]="true"
              [disabled]="action == 'view'"
              filterBy="name"
              [showClear]="true"
              styleClass="w-100"
              [(ngModel)]="selectedTypeItem"
              [ngModelOptions]="{standalone: true}"
              placeholder="Select a Kategori"
              (onChange)="onDropdownChange($event, 'type_id')"
              (onClear)="onDropdownClear('type_id')"
            >
              <ng-template pTemplate="selectedItem">
                <div class="" *ngIf="selectedTypeItem">
                  <div>{{ selectedTypeItem.name }}</div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="">
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
				</div> -->
        <div class="col-lg-12">
          <div class="form-group">
            <label class="text-black font-w600">Kode Kategori <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="code_kategori"
              required
              [(ngModel)]="fadd_role.code_kategori"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="code_kategori"
            />
          </div>
        </div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Nama Kategori<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="name_kategori"
              required
              [(ngModel)]="fadd_role.name_kategori"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="name_kategori"
            />
          </div>
				</div>

				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Keterangan<span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.description"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="description" rows="5"></textarea>
            <!-- <textarea class="form-control" id="description" rows="5"
            [(ngModel)]="fadd_role.description"
            name="description"
            formControlName="description"
            [disabled]="fadd_role.action == 'view'"
                  placeholder="Keterangan ?"
                  required></textarea> -->
            <!-- <input
              type="text"
              class="form-control"
              id="description"
              required
              [(ngModel)]="fadd_role.description"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="description"
            /> -->
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
