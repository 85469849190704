import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import * as XLSX from 'xlsx';
import { dataSeries } from 'src/app/charts/apex/line/zoomable-timeseries/data-series';
import { parseClassNames } from '@fullcalendar/angular';
import { TokenStorageService } from 'src/app/_services/token-storage.service';


@Component({
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Informasi</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <span class="text-danger">Anda yakin akan menghapus?</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
  </div>
  `
})
export class NgbdModalConfirm {
  @Input() public data: any;
  constructor(public modal: NgbActiveModal) {
    console.log(this.data)
  }

  OnSubmit(status:any) {
    this.modal.close(status);
  }
}

export interface Tutorial {
  id?: any;
  state?: string,
  code?: string,
  name?: string,
  reff_name?: string,
  phone_number?: string,
  id_country?: number,
  country?: string,
  id_city?: number,
  city?: string,
  post_code?: number,
  address?: string,
  arc_number?: string,
  is_aktif?: string,
  is_delete?: string,
  is_merge?: string,
  is_bayar?: string,
  is_copy?: string,
  is_bag?: string,
  connote?: string,
  ship_date?: string,
  code_resi_inbound?: string;
  airwaybill?: string,
  nameshippers?: string,
  nameconsignee?: string,
  namevendor?: string,
  shipper_id?: string,
  vendor_id?: string,
  awb_vendor?: string,
  status_vendor?: string,
  createdAt?: string,
  updatedAt?: string,
  full_name?: string,
  resi_lokal?: string,
  start_ship_date?: string,
  end_ship_date?: string,
  // published?: boolean;
  action?: string
}

const MODALS: {[name: string]: Type<any>} = {
  focusFirst: NgbdModalConfirm
};

@Component({
  selector: 'app-tr-cleansingscomplete',
  templateUrl: './tr-cleansingscomplete.component.html',
  styleUrls: ['./tr-cleansingscomplete.component.css']
})
export class TrCleansingscompleteComponent implements OnInit {


  tutorials: Tutorial[] = [];
  tutorialsTemp: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';
  shipper_id = '';
  ship_date = '';
  vendor_id = '';
  airwaybill='';
  nameconsignee='';
  code_resi_inbound='';
  resi_lokal='';
  start_ship_date = '';
  end_ship_date = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 2000, "ALL"];
  isCreated:boolean = true;

  vcombos:any = [];
  vcomboss:any = [];

  closeResult = '';
  modal: any;
  ExcelData: any;
  file: any;
  exceljsondata: any;
  arrayBuffer: any;

  awb_vendor: any;

  constructor(private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
    private router: Router) { }

    usersData:any = {};
    rolesString:any = '';
    isAdmin:boolean = false;
    isSuperadmin:boolean = false;
    isCustomer:boolean = false;
    isAgen:boolean = false;
    isFinance:boolean = false;
    isCleansings:boolean = false;
    isInbound:boolean = false;
    getUserData() {
      this.tokenStorage.getUsers().then(async res => {
        this.usersData = res;
        // console.log(res)
        this.usersData.role.forEach((e:any) => {
        this.rolesString = this.rolesString+e.name+', ';
        });
        // console.log(this.rolesString)
        if(this.rolesString.includes('Admin')) {
          this.isAdmin = true;
        }
        if(this.rolesString.includes('Superadmin')) {
          this.isSuperadmin = true;
        }
        if(this.rolesString.includes('Agen')) {
          this.isAgen = true;
        }
        if(this.rolesString.includes('Finance')) {
          this.isFinance = true;
        }
        if(this.rolesString.includes('Cleansings')) {
          this.isCleansings = true;
        }
        if(this.rolesString.includes('Inbound')) {
          this.isInbound = true;
        }
        // console.log(this.isAdmin)
      })
    }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.shipper_id = '';
    this.ship_date = '';
    this.vendor_id = '';
    this.airwaybill = '';
    this.nameconsignee = '';
		this.modalService.open(content, { size: 'lg' });
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
	}

  openimport(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.shipper_id = '';
    this.ship_date = '';
    this.vendor_id = '';
		this.modalService.open(content, { size: 'lg' });
    // this.modalService.open(content, { size: 'lg' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
	}

  setAwbvendor(sendCleansingModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendCleansingModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.awb_vendor = tutorial.awb_vendor;
    this.fadd_role.action = action;
  }
  setTovendor(sendCleansingvendorModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendCleansingvendorModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.vendor_id = tutorial.vendor_id;
    this.fadd_role.action = action;
  }

  setAwbs(sendCleansingawbModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendCleansingawbModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.airwaybill = tutorial.airwaybill;
    // this.fadd_role.manifest_date = tutorial.manifest_date;
    // this.fadd_role.invoice_no = tutorial.invoice_no;
    // this.fadd_role.mawb = tutorial.mawb;
    // this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.flight_id = tutorial.flight_id;
    // this.fadd_role.vendor_id = tutorial.vendor_id;
    // this.fadd_role.note = tutorial.note;
    // this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  submitted!:boolean;
  saveAwbvendor(): void {
    const data = {
      awb_vendor: this.fadd_role.awb_vendor,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('cleansings/setupdateawb/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  saveTovendor(): void {
    const data = {
      vendor_id: this.fadd_role.vendor_id,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('cleansings/senddatavendor/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  saveAwbs(): void {
    const data = {
      airwaybill: this.fadd_role.airwaybill,
    };
    // console.log(this.fadd_role.id, data, 'data')
      this.tutorialService.postData('cleansings/setupdateawbs/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodatashippers();
    this.retrieveCombodatavendors();
    this.getUserData();
  }



  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    // console.log(params[`shipper_id`], 'params')

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // console.log(this.shipper_id)
    params.shipper_id = this.shipper_id;
    params.vendor_id = this.vendor_id;
    params.ship_date = this.ship_date;
    params.airwaybill = this.airwaybill;
    params.nameconsignee = this.nameconsignee;
    params.code_resi_inbound = this.code_resi_inbound;
    params.resi_lokal = this.resi_lokal;
    params.start_ship_date = this.start_ship_date;
    params.end_ship_date = this.end_ship_date;
    // params.consignee_id = null
    // console.log(params)

    this.tutorialService.getAlls('cleansings/viewcomplete', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodatashippers(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('shippers/viewshipper', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombos = data;
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  retrieveCombodatavendors(): void {
    const params = this.getRequestParams(this.title, this.page, 100);
    // params.limit = 100;
    // console.log(params)
    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcomboss = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }

  searchTerm: string = '';
  searchChanged(evt:any) {
    this.initializeItems();

    const searchTerm = evt;

    if (!searchTerm) {
      return;
    }

    this.tutorials = this.tutorials.filter(item => {
      if (item.connote && searchTerm) {
        if (item.connote.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    // console.log('tes close searchTitle form search');
    // this.modal.close();
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    name: '',
    // ship_date: '',
  };

  // submitted!:boolean;
  saveTutorial(): void {
    const data = {
      name: this.fadd_role.name
    };

    if(this.isCreated) {
      this.tutorialService.postData('role/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('role/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    // console.log(tutorial)
    this.fadd_role = tutorial;
    this.fadd_role.action = action;
  }

  deleteData:any;
  deleteUser(data:any) {
    this.deleteData = data;
    let modal = this.modalService.open(MODALS['focusFirst']);
    modal.result.then((res:any) => {
      // console.log(res)
      if(res == 'Ok') {
        this.tutorialService.postData('cleansings/delete/'+this.deleteData.id, {}).subscribe(res => {
          this.toastService.show('Successfully delete data.', { classname: 'bg-success text-light', delay: 10000 });
          this.retrieveTutorials();
        })
      }
    });
  }

  copyCleansing(data:any) {
    var conf = confirm('Anda yakin akan copy?');
    if (conf) {
      // console.log(data, 'data')
      // this.tutorialService.postData('cleansing/copy', data)
      this.tutorialService.postData('cleansings/copy/'+data.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            // this.fadd_role = {};
            this.presentToast(response.message, 'success');
            // this.submitted = true;
            // this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  openData(tutorial:any, action:any) {
    const param: NavigationExtras = {
      queryParams: {
        info: JSON.stringify(tutorial),
        action: JSON.stringify(action)
      }
    }
    console.log(param,'params')
    this.router.navigate(['/admin/form-cleansing'], param);
  }

  openPrint(tutorial:any, action:any) {
    // const param: NavigationExtras = {
    //   queryParams: {
    //     info: JSON.stringify(tutorial),
    //     action: JSON.stringify(action)
    //   }
    // }
    // console.log(tutorial.id, 'params')
    var anchor = document.createElement('a');
    // anchor.href = 'http://localhost/reportnissa/welcome/laporan/'+tutorial.id;
    anchor.href = 'https://report.unicomputer.id/welcome/laporan/'+tutorial.id;
    anchor.target="_blank";
    anchor.click();
    this.router.navigate(['/admin/app-tr-cleansingscomplete']);
    // this.router.navigate(['/admin/form-cleansing'], param);
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
     this.file = event.target.files[0];
    }
  }

  Downloadtemps(){
    var anchor = document.createElement('a');
    anchor.href = 'https://api.unicomputer.id/uploads/template/template_import_cleansings.xlsx';
    // anchor.href = 'http://localhost:44444/uploads/template/template_import_cleansings.xlsx';
    anchor.target="_blank";
    anchor.click();
    this.presentToast('Download Template Success', 'success');
    // this.submitted = true;
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  Upload() {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, {type:"binary"});
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
          this.exceljsondata = XLSX.utils.sheet_to_json(worksheet,{raw:true, defval:""});

          // const params = this.getRequestParams(this.title, this.page, this.pageSize);
          // // console.log(this.shipper_id)
          // params.shipper_id = this.shipper_id;
          // params.vendor_id = this.vendor_id;
          // params.ship_date = this.ship_date;
          // console.log(this.shipper_id,this.vendor_id,this.ship_date, 'this.shipper_id,this.vendor_id,this.ship_date');

          // console.log(this.exceljsondata);
          let senddata = this.exceljsondata;
          let ship_dates = this.ship_date;
          let shipper_ids = this.shipper_id;
          let vendor_ids = this.vendor_id;
          // console.log(senddata);
          this.tutorialService.postData('cleansings/importexcel', {
            data: senddata,
            ship_dates: ship_dates,
            shipper_ids: shipper_ids,
            vendor_ids: vendor_ids,
          })
          .subscribe(
            async response => {
              // console.log(response);
              this.fadd_role = {};
              this.presentToast(response.message, 'success');
              this.submitted = true;
              this.modalService.dismissAll();
              this.retrieveTutorials();
            },
            async error => {
              // console.log(error);
              this.presentToast(error.error.errors, 'error');
            });
          //  this.providerservice.importexcel(this.exceljsondata).subscribe(data=>{
          //  })
      }
     fileReader.readAsArrayBuffer(this.file);
 }

 checkUncheckAll(event:any) {
      var checkboxes = document.getElementsByTagName('input');
      if (event.target.checked) {
          for (var i = 0; i < checkboxes.length; i++) {
            // console.log(i, '1')
              if (checkboxes[i].type == 'checkbox') {
                  checkboxes[i].checked = true;
              }
          }
      } else {
          for (var i = 0; i < checkboxes.length; i++) {
              // console.log(i, '2')
              if (checkboxes[i].type == 'checkbox') {
                  checkboxes[i].checked = false;
              }
          }
      }
  }

  checkAll(ev: any) {
    this.tutorials.forEach(x => x.state = ev.target.checked)
  }

  isAllChecked() {
    return this.tutorials.every(_ => _.state);
  }

  check(tutorial: any) {
    tutorial.state = !tutorial.state;
  }

  setMerge() {
    var conf = confirm('Anda yakin akan Merge data ini ?');
    if (conf) {
      // console.log(this.tutorials.id, 'this.tutorials')
      // console.log(ev, 'ev')
      let vresult: Tutorial[] = []
      this.tutorials.forEach(async x => {
        if(x.state) {
          // const params = x;
          // console.log(x, 'vresult')
          vresult.push(x)
          // console.log(params, 'params')
          // let datas = await Promise.all(x.state);
          // console.log(x, 'datas');
          // let params.data = []
          // params.statustracking = this.status;
          // // params.manifest_date = this.manifest_date;
          // // console.log(x, this.status, 'x, this.status,');
          // // alert(x.tutorial);
          // this.tutorialService.postData('cleansings/createmerge', params)
          // .subscribe(
          //   async response => {
          //     // console.log(response);
          //     this.fadd_role = {};
          //     this.presentToast(response.message, 'success');
          //     this.submitted = true;
          //     this.modalService.dismissAll();
          //     this.retrieveTutorials();
          //   },
          //   async error => {
          //     // console.log(error);
          //     this.presentToast(error.error.errors, 'error');
          //   });
        }
      });
      if(vresult.length < 2){
        // console.log('untuk merge tidak boleh kurang dari 2')
        this.presentToast('Merge Tidak Boleh Kurang dari 2', 'error');
      }else{
          this.tutorialService.postData('cleansings/createmerge', vresult)
          .subscribe(
            async response => {
              // console.log(response);
              this.fadd_role = {};
              this.presentToast(response.message, 'success');
              this.submitted = true;
              this.modalService.dismissAll();
              this.retrieveTutorials();
            },
            async error => {
              // console.log(error);
              this.presentToast(error.error.errors, 'error');
            });
        // console.log(vresult.length, 'params');
      }
      // console.log(vresult, 'params');
    }
  }

  setBulkPrint() {
    var conf = confirm('Anda yakin akan Cetak data ini ?');
    if (conf) {
      let vresult: Tutorial[] = []
      this.tutorials.forEach(async x => {
        if(x.state) {
          // console.log(x,'x')
          // vresult.push({"id": x.id})
          vresult.push(x.id)
        }
      });



      var anchor = document.createElement('a');
      // anchor.href = 'http://localhost/reportnissa/prints/laporan?id='+JSON.stringify(vresult);
      anchor.href = 'https://report.unicomputer.id/prints/laporan?id='+JSON.stringify(vresult);
      anchor.target="_blank";
      anchor.click();
      this.router.navigate(['/admin/app-tr-cleansingscomplete']);

        // this.tutorialService.postData('cleansings/bulkprints', vresult)
        // .subscribe(
        //   async response => {
        //     // console.log(response);
        //     this.fadd_role = {};
        //     this.presentToast(response.message, 'success');
        //     this.submitted = true;
        //     this.modalService.dismissAll();
        //     this.retrieveTutorials();
        //   },
        //   async error => {
        //     this.presentToast(error.error.errors, 'error');
        //   });
    }
  }

  // OKE
  // ReadExcel(event: any) {
  //   let file = event.target.files[0];

  //   let fileReader = new FileReader();
  //   fileReader.readAsBinaryString(file);

  //   fileReader.onload = (e) => {
  //      var workBook = XLSX.read(fileReader.result,{type:'binary'});
  //      var sheetNames = workBook.SheetNames;
  //       this.ExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]])
  //      console.log(this.ExcelData)

  //       //  this.arrayBuffer = fileReader.result;
  //       //  var data = new Uint8Array(this.arrayBuffer);
  //       //  var arr = new Array();
  //       //  for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
  //       //  var bstr = arr.join("");
  //       //  var workbook = XLSX.read(bstr, {type:"binary"});
  //       //  var first_sheet_name = workbook.SheetNames[0];
  //       //  var worksheet = workbook.Sheets[first_sheet_name];
  //       //  this.exceljsondata = XLSX.utils.sheet_to_json(worksheet,{raw:true, defval:""});

  //       // console.log(this.exceljsondata);
  //       //  this.providerservice.importexcel(this.exceljsondata).subscribe(data=>{
  //       //  })
  //    }

  //   // if (event.target.files.length > 0) {
  //   //  this.file = event.target.files[0];
  //   // }
  // }

//   Upload() {
//        let fileReader = new FileReader();
//      fileReader.onload = (e) => {
//          this.arrayBuffer = fileReader.result;
//          var data = new Uint8Array(this.arrayBuffer);
//          var arr = new Array();
//          for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
//          var bstr = arr.join("");
//          var workbook = XLSX.read(bstr, {type:"binary"});
//          var first_sheet_name = workbook.SheetNames[0];
//          var worksheet = workbook.Sheets[first_sheet_name];
//          this.exceljsondata = XLSX.utils.sheet_to_json(worksheet,{raw:true, defval:""});

//         console.log(this.exceljsondata);
//          this.providerservice.importexcel(this.exceljsondata).subscribe(data=>{
//          })
//      }
//      fileReader.readAsArrayBuffer(this.file);
//  }

}
