<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">User</a></li>
                  </ol>
              </div>

          </div>

      </div>

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List User</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Username</th>
                      <th scope="col">Full name</th>
                      <th scope="col">Hp</th>
                      <th scope="col">Email</th>
                      <th scope="col">Role</th>
                      <th scope="col">Mitra</th>
                      <th scope="col">Status</th>
                      <!-- <th scope="col">Password</th> -->
                      <!-- <th scope="col">Level</th> -->
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>
                          {{ tutorial.short_name }}
                        </td>
                        <td>
                          {{ tutorial.full_name }}
                        </td>
                        <td>
                          {{ tutorial.hp }}
                        </td>
                        <td>{{ tutorial.email }}</td>
                        <td>{{ tutorial.namer }}</td>
                        <td>{{ tutorial.nameshippers }}</td>
                        <td *ngIf="tutorial.status == 'N'">Non Aktif</td>
                        <td *ngIf="tutorial.status == 'Y'">Aktif</td>
                        <!-- <td>{{ tutorial.password }}</td> -->
                        <!-- <td>{{ tutorial.level }}</td> -->
                        <td>
                          <button type="button" class="btn btn-info btn-xxs mr-2 mb-2" (click)="editUser(sendMessageModal, tutorial, 'update')" placement="left" ngbTooltip="Edit">
                            <i class="fa fa-pencil color-info"></i>
                            </button>
                          <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="deleteUsers(tutorial)" placement="left" ngbTooltip="Delete">
                            <i class="fa fa-trash color-info"></i>
                            </button>
            
                          <button type="button" class="btn btn-success btn-xxs mr-2 mb-2" (click)="activeUsers(tutorial)" placement="left" ngbTooltip="Aktifkan User" *ngIf="tutorial.status == 'N'">
                            <i class="fa fa-check color-info"></i>
                            </button>

                            <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="nonActiveUsers(tutorial)" placement="left" ngbTooltip="Non Aktifkan User" *ngIf="tutorial.status == 'Y'">
                              <i class="fa fa-times color-info"></i>
                              </button>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize" (pageChange)="handlePageChange($event)">
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize" (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>

              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form User</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Username <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="short_name"
              required
              [(ngModel)]="fadd_role.short_name"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="short_name"
            />
          </div>
				</div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Fullname <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="full_name"
              required
              [(ngModel)]="fadd_role.full_name"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="full_name"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Handphone <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="hp"
              required
              [(ngModel)]="fadd_role.hp"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="hp"
            />
          </div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Email <span class="required">*</span></label>
            <input
              type="email"
              class="form-control"
              id="email"
              required
              [(ngModel)]="fadd_role.email"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="email"
            />
          </div>
				</div>
        <!-- <div class="col-lg-6" *ngIf="isCreated"> -->
        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Password <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="password"
              required
              [(ngModel)]="fadd_role.password"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="password"
            />
          </div>
				</div>

        <div class="col-lg-12 mb-3">
          <label class="text-black font-w600">Level <span class="required">*</span></label>
            <select class="form-control default-select" *ngIf="vcombo.length > 0" id="level" [(ngModel)]="fadd_role.level" #name="ngModel" name="level">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombo" value="{{n.id}}">{{n.description}}</option>
            </select>
        </div>

        <div class="col-lg-12 mb-3">
          <label class="text-black font-w600">Mitra <span class="required">*</span></label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="fadd_role.shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
