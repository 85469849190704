import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SelectItem, PrimeNGConfig } from "primeng/api";
import { finalize } from 'rxjs/operators';


export interface Tutorial {
  id?: any;
  nama?: string,
  no_telp?: string,
  produkheader?: string,
  total_harga?: string,
  keterangan?: string,
  // code_product?: string,
  // name_product?: string,
  // name_kategori?: string,
  // stock?: string,
  // harga?: string,
  // name?: string,
  // phonename_product?: string,
  // phone_number?: string,
  // keterangan?: string,
  // template_option?: string,
  // fileimage?: string,
  // event?: string,
  // state?: any;
  // published?: boolean;
  action?: string
}


@Component({
  selector: 'app-tr-simulasihargas',
  templateUrl: './tr-simulasihargas.component.html',
  styleUrls: ['./tr-simulasihargas.component.css']
})
export class TrSimulasihargasComponent implements OnInit {

  isLoading$ = false;

  image: File | undefined;
  resData: any;
  selectedFile: any;
  //  selectedFile = null;

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  // code_product: any;
  // name_product: any;
  // stock: any;
  // harga: any;
  harga1: any;
  harga2: any;
  harga3: any;
  harga4: any;
  harga5: any;
  harga6: any;
  harga7: any;
  harga8: any;
  harga9: any;
  harga10: any;
  harga11: any;
  keterangan: any;
  status1: any;
  nama: any;
  alamat: any;
  email: any;
  no_telp: any;
  produkheader: any;
  name_product1: any;
  name_product2: any;
  name_product3: any;
  name_product4: any;
  name_product5: any;
  name_product6: any;
  name_product7: any;
  name_product8: any;
  name_product9: any;
  name_product10: any;
  name_product11: any;
  total_harga: any;
  // name: any;
  // phone_number: any;
  // address: any;
  // template_option: any;
  // fileimage: any;
  id: any;

  // type_id: any;

  // purc_price: any;
  // sell_price: any;
  // weight: any;

  // countries: any[] = [];
  // selectedShipperPhoneNumberCountry: any = {};

  action: any = '';
  // phonename_product: any;

  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  typeItemsH: any[] = [];
  selectedTypeItemH: any | undefined;

  typeItemsKeyboard: any[] = [];
  selectedTypeItemKeyboard: any | undefined;

  typeItemslcd: any[] = [];
  selectedTypeItemlcd: any | undefined;

  typeItemsbattery: any[] = [];
  selectedTypeItembattery: any | undefined;

  typeItemsadaptor: any[] = [];
  selectedTypeItemadaptor: any | undefined;

  typeItemswindows: any[] = [];
  selectedTypeItemwindows: any | undefined;

  typeItemsengsel: any[] = [];
  selectedTypeItemsengsel: any | undefined;

  typeItemsmatitotal: any[] = [];
  selectedTypeItemsmatitotal: any | undefined;

  typeItemscleaning: any[] = [];
  selectedTypeItemscleaning: any | undefined;

  typeItemsram: any[] = [];
  selectedTypeItemsram: any | undefined;

  typeItemsssd: any[] = [];
  selectedTypeItemsssd: any | undefined;

  typeItemsmaintenance: any[] = [];
  selectedTypeItemsmaintenance: any | undefined;

  // cities: any[] = [];

  // selectedCities: any[] | undefined;

  ngOnInit(): void {
    this.retrieveTutorials();
    // this.fetchDropdownValues();

    this.retrieveCombodatatypeItems();
    this.retrieveCombodatatypeItemsH();
    // this.retrieveCombodatatypeItemsKeyboard();

    // console.log(this.harga1,this.harga2,this.harga3,'ss')

    this.primengConfig.ripple = true;
  }

  // event: any;
  angForm: FormGroup | any;
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig
  ) {
    this.createForm();
    config.backdrop = 'static';
    config.keyboard = false;
    config.animation = false;
  }


  createForm() {
    this.angForm = this.fb.group({
      nama: [''],
      no_telp: [''],
      email: [''],
      alamat: [''],
      keterangan: [''],
      // name_product: ['', [Validators.required]],
      // stock: ['', [Validators.required]],
      // // harga: ['', [Validators.required]],
      // keterangan: ['', [Validators.required]],
      // address: ['', [Validators.required]],
      // template_option: ['', [Validators.required]]
    });


  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.id = null;
    // this.code_product = null;
    // this.name_product = null;
    // this.stock = '';
    this.nama = '';
    this.no_telp = '';
    this.email = '';
    this.alamat = '';
    this.keterangan = '';
    this.selectedTypeItem = '';
    this.selectedTypeItemH = '';
    this.selectedTypeItemKeyboard = '';
    this.selectedTypeItemlcd = '';
    this.selectedTypeItemadaptor = '';
    this.selectedTypeItemwindows = '';
    this.selectedTypeItemsengsel = '';
    this.selectedTypeItemsmatitotal = '';
    this.selectedTypeItemscleaning = '';
    this.selectedTypeItemsram = '';
    this.selectedTypeItemsssd = '';
    this.selectedTypeItemsmaintenance = '';
    this.harga1 = 0;
    this.harga2 = 0;
    this.harga3 = 0;
    this.harga4 = 0;
    this.harga5 = 0;
    this.harga6 = 0;
    this.harga7 = 0;
    this.harga8 = 0;
    this.harga9 = 0;
    this.harga10 = 0;
    this.harga11 = 0;
    this.status1 = false;
    this.files = [];
    // this.phone_number = null;
    // this.address = null;
    // this.template_option = null;
    // this.selectedShipperPhoneNumberCountry = {};
    this.modalService.open(content, { size: 'xl'  });
  }





  // START
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    // console.log(this.selectedFile);
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('simulasi/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    // code_product: '',
    // name_product: '',
    // stock: '',
    // harga: '',
    // keterangan: '',
    // phone_number: '',
    // address: '',
    // template_option: '',
    // fileimage: '',
    // published: false
  };

  submitted!: boolean;
  saveSimulasi(): void {

     // let data = this.angForm.value;
     // console.log(this.selectedTypeItemlcd == '','datasave')

    const data = {
      id_product_header: this.selectedTypeItemH == '' ? '' : this.selectedTypeItemH.id,
      id_produk_1: this.selectedTypeItemlcd == '' ? '' : this.selectedTypeItemlcd.id,
      harga1: this.selectedTypeItemlcd == '' ? 0 : this.selectedTypeItemlcd.harga,
      id_produk_2: this.selectedTypeItemKeyboard == '' ? '' : this.selectedTypeItemKeyboard.id,
      harga2: this.selectedTypeItemKeyboard == '' ? 0 : this.selectedTypeItemKeyboard.harga,
      id_produk_3: this.selectedTypeItembattery == undefined ? '' : this.selectedTypeItembattery.id,
      harga3: this.selectedTypeItembattery == undefined ? 0 : this.selectedTypeItembattery.harga,
      id_produk_4: this.selectedTypeItemadaptor == '' ? '' : this.selectedTypeItemadaptor.id,
      harga4: this.selectedTypeItemadaptor == '' ? 0 : this.selectedTypeItemadaptor.harga,
      id_produk_5: this.selectedTypeItemwindows == '' ? '' : this.selectedTypeItemwindows.id,
      harga5: this.selectedTypeItemwindows == '' ? 0 : this.selectedTypeItemwindows.harga,
      id_produk_6: this.selectedTypeItemsengsel == '' ? '' : this.selectedTypeItemsengsel.id,
      harga6: this.selectedTypeItemsengsel == '' ? 0 : this.selectedTypeItemsengsel.harga,
      id_produk_7: this.selectedTypeItemsmatitotal == '' ? '' : this.selectedTypeItemsmatitotal.id,
      harga7: this.selectedTypeItemsmatitotal == '' ? 0 : this.selectedTypeItemsmatitotal.harga,
      id_produk_8: this.selectedTypeItemscleaning == '' ? '' : this.selectedTypeItemscleaning.id,
      harga8: this.selectedTypeItemscleaning == '' ? 0 : this.selectedTypeItemscleaning.harga,
      id_produk_9: this.selectedTypeItemsram == '' ? '' : this.selectedTypeItemsram.id,
      harga9: this.selectedTypeItemsram == '' ? 0 : this.selectedTypeItemsram.harga,
      id_produk_10: this.selectedTypeItemsssd == '' ? '' : this.selectedTypeItemsssd.id,
      harga10: this.selectedTypeItemsssd == '' ? 0 : this.selectedTypeItemsssd.harga,
      id_produk_11: this.selectedTypeItemsmaintenance == '' ? '' : this.selectedTypeItemsmaintenance.id,
      harga11: this.selectedTypeItemsmaintenance == '' ? 0 : this.selectedTypeItemsmaintenance.harga,
      nama: this.nama,
      no_telp: this.no_telp,
      email: this.email,
      alamat: this.alamat,
      keterangan: this.keterangan,
    };
    console.log(data,'data')
    if (this.isCreated) {
      this.tutorialService.postData('simulasi/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('simulations/update/' + this.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal: any, result: any, action: any) {
    console.log(result,'tutorial')
    this.isCreated = false;
    this.modalService.open(sendMessageModal, { size: 'lg' });
    this.id = result.id;
    // this.code_product = tutorial.code_product;
    // this.name_product = tutorial.name_product;
    this.selectedTypeItemH = this.typeItemsH.find((e: any) => e.id == result.id_product_header);
    this.selectedTypeItemlcd = this.typeItemslcd.find((e: any) => e.id == result.id_produk_1);
    // console.log(this.selectedTypeItemH,'  this.selectedTypeItemlcd')
    this.selectedTypeItemKeyboard = this.typeItemsKeyboard.find((e: any) => e.id == result.id_produk_2);
    this.selectedTypeItembattery = this.typeItemsbattery.find((e: any) => e.id == result.id_produk_3);
    this.selectedTypeItemadaptor = this.typeItemsadaptor.find((e: any) => e.id == result.id_produk_4);
    this.selectedTypeItemwindows = this.typeItemswindows.find((e: any) => e.id == result.id_produk_5);
    this.selectedTypeItemsengsel = this.typeItemsengsel.find((e: any) => e.id == result.id_produk_6);
    this.selectedTypeItemsmatitotal = this.typeItemsmatitotal.find((e: any) => e.id == result.id_produk_7);
    this.selectedTypeItemscleaning = this.typeItemscleaning.find((e: any) => e.id == result.id_produk_8);
    this.selectedTypeItemsram = this.typeItemsram.find((e: any) => e.id == result.id_produk_9);
    this.selectedTypeItemsssd = this.typeItemsssd.find((e: any) => e.id == result.id_produk_10);
    this.selectedTypeItemsmaintenance = this.typeItemsmaintenance.find((e: any) => e.id == result.id_produk_11);
    // this.stock = tutorial.stock;
    // this.harga = tutorial.harga;
    this.nama = result.nama;
    this.email = result.email;
    this.no_telp = result.no_telp;
    this.alamat = result.alamat;
    this.keterangan = result.keterangan;

    this.produkheader= result.produkheader;
    this.name_product1= result.name_product1;
    this.name_product2= result.name_product2;
    this.name_product3= result.name_product3;
    this.name_product4= result.name_product4;
    this.name_product5= result.name_product5;
    this.name_product6= result.name_product6;
    this.name_product7= result.name_product7;
    this.name_product8= result.name_product8;
    this.name_product9= result.name_product9;
    this.name_product10= result.name_product10;
    this.name_product11= result.name_product11;

    this.harga1= result.harga1;
    this.harga2= result.harga2;
    this.harga3= result.harga3;
    this.harga4= result.harga4;
    this.harga5= result.harga5;
    this.harga6= result.harga6;
    this.harga7= result.harga7;
    this.harga8= result.harga8;
    this.harga9= result.harga9;
    this.harga10= result.harga10;
    this.harga11= result.harga11;
    this.total_harga= result.total_harga;
    // this.keterangan: result.keterangan;

    // this.files = [];
    // this.address = tutorial.address;
    // this.template_option = tutorial.template_option;
    // this.fileimage = tutorial.fileimage;
    // console.log(tutorial,'tutorial')
    // let selected = this.typeItems.find((e: any) => e.id == tutorial.id_kategori);
    // let selected2 = this.typeItemsH.find((e: any) => e.id == tutorial.id_product_header);
    // // // console.log(selectedShipper)
    // this.selectedTypeItem = selected;
    // this.selectedTypeItemH = selected2;
    this.fadd_role.action = action;
  }

  deleteSimulasi(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`simulasi/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  onAutoCompleteSelected(event: any, tipe: string) {
    const val = {
      value: event,
    };
    this.onDropdownChange(val, tipe);
  }

  onDropdownChange(event: any, tipe: string) {

    if(tipe === 'type_id') {
      this.selectedTypeItem = event.value;
    } if(tipe === 'type_idh') {
      // this.fetchSubItem(event.value.id);
      this.selectedTypeItemH = event.value;
      // console.log(event.value.id_product_header == null,'event.value')
      // let stat = event.value.id_product_header == null;
      this.status1 = true;

      // console.log(event.value,'event.value')
      this.retrieveCombodatatypeItemsKeyboard(event.value.id);
      this.retrieveCombodatatypeItemslcd(event.value.id);
      this.retrieveCombodatatypeItemsbattery(event.value.id);
      this.retrieveCombodatatypeItemsadaptor(event.value.id);
      this.retrieveCombodatatypeItemswindows(event.value.id);
      this.retrieveCombodatatypeItemsengsel(event.value.id);
      this.retrieveCombodatatypeItemsmatitotal(event.value.id);
      this.retrieveCombodatatypeItemscleaning(event.value.id);
      this.retrieveCombodatatypeItemsram(event.value.id);
      this.retrieveCombodatatypeItemsssd(event.value.id);
      this.retrieveCombodatatypeItemsmaintenance(event.value.id);
    }
    if(tipe === 'type_idlcd') {
      // console.log(event.value,'lcd')
      this.selectedTypeItemlcd = event.value;
      this.harga1 = event.value.harga;

    }
    if(tipe === 'type_idkeyboard') {
      // this.fetchSubItem(event.value.id);
      this.selectedTypeItemKeyboard = event.value;
      this.harga2 = event.value.harga;
    }

    if(tipe === 'type_idbattery') {
      this.selectedTypeItembattery = event.value;
      this.harga3 = event.value.harga;
    }
    if(tipe === 'type_idadaptor') {
      this.selectedTypeItemadaptor = event.value;
      this.harga4 = event.value.harga;
    }
    if(tipe === 'type_idwindows') {
      this.selectedTypeItemwindows = event.value;
      this.harga5 = event.value.harga;
    }
    if(tipe === 'type_idengsel') {
      this.selectedTypeItemsengsel = event.value;
      this.harga6 = event.value.harga;
    }
    if(tipe === 'type_idmatitotal') {
      this.selectedTypeItemsmatitotal = event.value;
      this.harga7 = event.value.harga;
    }
    if(tipe === 'type_idcleaning') {
      this.selectedTypeItemscleaning = event.value;
      this.harga8 = event.value.harga;
    }
    if(tipe === 'type_idram') {
      this.selectedTypeItemsram = event.value;
      this.harga9 = event.value.harga;
    }
    if(tipe === 'type_idssd') {
      this.selectedTypeItemsssd = event.value;
      this.harga10 = event.value.harga;
    }
    if(tipe === 'type_idmaintenance') {
      this.selectedTypeItemsmaintenance = event.value;
      this.harga11 = event.value.harga;
    }
    // else if(tipe === 'subtype_id') {
    //   this.selectedSubTypeItem = event.value;
    // }
  }

  // onDropdownChange(event: any, tipe: string) {
  //   this.selectedShipperPhoneNumberCountry = event.value;
  // }

  // onDropdownClear(tipe: string) {
  //   // if(tipe === 'shipper_phone') {
  //   this.selectedShipperPhoneNumberCountry = {};
  //   // }
  // }

  onDropdownClear(tipe: string) {
     if(tipe === 'type_id') {
      this.selectedTypeItem = {};
     }
     if(tipe === 'type_idh') {
        this.selectedTypeItemH = {};
        this.selectedTypeItemlcd = {};
        this.harga1 = 0;
        this.selectedTypeItemKeyboard = {};
        this.harga2 = 0;
        this.selectedTypeItembattery = {};
        this.harga3 = 0;
        this.selectedTypeItemadaptor = {};
        this.harga4 = 0;
        this.selectedTypeItemwindows = {};
        this.harga5 = 0;
        this.selectedTypeItemsengsel = {};
        this.harga6 = 0;
        this.selectedTypeItemsmatitotal = {};
        this.harga7 = 0;
        this.selectedTypeItemscleaning = {};
        this.harga8 = 0;
        this.selectedTypeItemsram = {};
        this.harga9 = 0;
        this.selectedTypeItemsssd = {};
        this.harga10 = 0;
        this.selectedTypeItemsmaintenance = {};
        this.harga11 = 0;
     }
     if(tipe === 'type_idlcd') {
      this.selectedTypeItemlcd = {};
      this.harga1 = 0;
     }
     if(tipe === 'type_idkeyboard') {
      this.selectedTypeItemKeyboard = {};
      this.harga2 = 0;
     }
     if(tipe === 'type_idbattery') {
      this.selectedTypeItembattery = {};
        this.harga3 = 0;
     }
     if(tipe === 'type_idadaptor') {
      this.selectedTypeItemadaptor = {};
        this.harga4 = 0;
     }
     if(tipe === 'type_idwindows') {
      this.selectedTypeItemwindows = {};
        this.harga5 = 0;
     }
     if(tipe === 'type_idengsel') {
      this.selectedTypeItemsengsel = {};
        this.harga6 = 0;
     }
     if(tipe === 'type_idmatitotal') {
      this.selectedTypeItemsmatitotal = {};
        this.harga7 = 0;
     }
     if(tipe === 'type_idcleaning') {
      this.selectedTypeItemscleaning = {};
        this.harga8 = 0;
     }
     if(tipe === 'type_idram') {
      this.selectedTypeItemsram = {};
        this.harga9 = 0;
     }
     if(tipe === 'type_idssd') {
      this.selectedTypeItemsssd = {};
        this.harga10 = 0;
     }
     if(tipe === 'type_idmaintenance') {
      this.selectedTypeItemsmaintenance = {};
        this.harga11 = 0;
     }
  }




  retrieveCombodatatypeItems(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('kategori_produk/combodata', params)
      .subscribe(
        response => {
          const { data } = response;
          this.typeItems = data;
          console.log(this.typeItems, 'tset')
          // this.fetchSubItem(this.typeItems.id);
          // console.log(this.vcombo, 'this.vcombo');
          // this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  retrieveCombodatatypeItemsH(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.tutorialService.getAlls('products/combodataheader', params)
      .subscribe(
        response => {
          const { data } = response;
          this.typeItemsH = data;
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

   retrieveCombodatatypeItemsKeyboard(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'KEYBOARD',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsKeyboard = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }

   retrieveCombodatatypeItemslcd(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'LCD',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemslcd = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }

   retrieveCombodatatypeItemsbattery(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'BATTERY',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsbattery = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemsadaptor(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'ADAPTOR',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsadaptor = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemswindows(event:any) {
      let data = {
        // id_product_header: event,
        code_kategori:'WINDOWS',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemswindows = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemsengsel(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'ENGSEL',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsengsel = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemsmatitotal(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'MATI',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsmatitotal = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemscleaning(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'CLEANING',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemscleaning = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemsram(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'RAM',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsram = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemsssd(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'SSD',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsssd = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }
  retrieveCombodatatypeItemsmaintenance(event:any) {
      let data = {
        id_product_header: event,
        code_kategori:'MAINTENANCE',
      }
      this.tutorialService.postData('brand_produk/subheaders', data)
        .subscribe(
          response => {
            const { data } = response;
            this.typeItemsmaintenance = data;
          },
          error => {
            this.presentToast(error.error.errors, 'error');
          });
  }

  file: any;
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  files: File[] = [];
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  currencyStr (value:any) {
    var formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  };



}
